import React, {useEffect, useRef, useState} from "react";
import Editor, { useMonaco } from '@monaco-editor/react';
import prettyStringify from 'json-stringify-pretty-compact';

let startMinizinc = `% eight queens puzzle
include "alldifferent.mzn";

array [1..8] of var 1..8: q;

constraint alldifferent(q);
constraint alldifferent(i in 1..8)(q[i] + i);
constraint alldifferent(i in 1..8)(q[i] - i);

solve satisfy;`

let startResultText = '{"solutions": [{"q": [4, 2, 7, 3, 6, 8, 5, 1]}], "status": "SATISFIED"}'

let language = {
    keywords: [
        'include',
        'array',
        'constraint',
        'solve',
        'satisfy',
        'true',
        'false',
        'alldifferent',
        'in',
        'for',
        'forall',
        'if',
        'then',
        'maximize',
        'minimize',
        'sum',
        'max',
        'min',
        'abs',
        'where',
        'int_search',
    ],

    typeKeywords: [
        'int',
        'float',
        'array',
        'bool',
        'enum',
        'list',
        'of',
        'set',
        'par',
        'string',
        'var',
    ],

    operators: [
        '+',
        '-',
        '*',
        '/',
    ],

    // we include these common regular expressions
    symbols: /[=><!~?:&|+\-*/^%]+/,
    escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

    // The main tokenizer for our languages
    tokenizer: {
        root: [
            // identifiers and keywords
            [
                /[a-zA-Z_]\w*/,
                {
                    cases: {
                        '@keywords': {token: 'keyword.$0'},
                        '@typeKeywords': {token: 'type'},
                    }
                }
            ],
            // whitespace
            {include: '@whitespace'},

            // keys
            [/(,)(\s*)([a-zA-Z_]\w*)(\s*)(:)(?!:)/, ['delimiter', '', 'key', '', 'delimiter']],
            [/({)(\s*)([a-zA-Z_]\w*)(\s*)(:)(?!:)/, ['@brackets', '', 'key', '', 'delimiter']],

            // delimiters and operators
            [/[{}()[\]]/, '@brackets'],
            [
                /@symbols/,
                {
                    cases: {
                        '@operators': 'delimiter',
                        '@default': ''
                    }
                }
            ],

            // numbers
            [/\d+\.\d+([eE][-+]?\d+)?/, 'number.float'],
            [/\d+?/, 'number'],

            // delimiter: after number because of .\d floats
            [/[;,.]/, 'delimiter'],

            // strings: recover on non-terminated strings
            [/"([^"\\]|\\.)*$/, 'string.invalid'], // non-teminated string
            [/'([^'\\]|\\.)*$/, 'string.invalid'], // non-teminated string
            [/"/, 'string', '@string."'],
            [/'/, 'string', "@string.'"]
        ],

        whitespace: [
            [/[ \t\r\n]+/, ''],
            [/%.*$/, 'comment']
        ],

        string: [
            [/[^\\"']+/, 'string'],
            [/@escapes/, 'string.escape'],
            [/\\./, 'string.escape.invalid'],
            [
                /["']/,
                {
                    cases: {
                        '$#==$S2': {token: 'string', next: '@pop'},
                        '@default': 'string'
                    }
                }
            ]
        ]
    }
}

function getCookieValue(a) {
  const b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
}

function getInitialHash() {
  const hash = window.location.pathname.match(/\/s\/(.+)/);
  if (hash) {
    return hash[1];
  }
}

function App() {
    const initialHash = getInitialHash();
    let initialEditor = ""
    let initialResult = ""
    if (!initialHash) {
        initialEditor = startMinizinc
        initialResult = startResultText
    }

    const [isEditorReady, setIsEditorReady] = useState(false);
    const [isRunningMinizinc, setIsRunningMinizinc] = useState(false);
    const [loadedCode, setLoadedCode] = useState(initialEditor);

    const editorRef = useRef(null);
    const resultRef = useRef(null);

    const monaco = useMonaco();
    useEffect(() => {
        if (monaco) {
            monaco.languages.register({id: 'minizinc'});
            monaco.languages.setMonarchTokensProvider('minizinc', language);
        }
    }, [monaco]);

    useEffect(()=>{
        const initialHash = getInitialHash();
        if (initialHash) {
            fetch('/api/snippet/' + initialHash + '/',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookieValue("csrftoken")
                    }
                },
            ).then(
                function (response) {
                    if (response.status !== 200) {
                        setLoadedCode('HTTP Error. Status: ' + response.status);
                        return;
                    }

                    response.json().then(function (data) {
                        setLoadedCode(data["code"]);
                    });
                }
            ).catch(function (err) {
                setLoadedCode('HTTP Error: ' + err);
            });
        }
    }, [])

    function runMinizinc() {
        setIsRunningMinizinc(true);
        resultRef.current.setValue('');
        fetch('https://run.disopt.com/run', {
                method: 'post',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    "model": editorRef.current.getValue(),
                    "nr_solutions": 1,
                    "timeout_sec": 20,
                    "all_solutions": false
                }),
            }
        ).then(
            function (response) {
                setIsRunningMinizinc(false);

                response.json().then(function (data) {
                    let json = prettyStringify(data, {maxLength: 80, indent: 2});
                    if (response.status === 200) {
                        resultRef.current.setValue(json);
                    } else if (data.hasOwnProperty('detail')) {
                        resultRef.current.setValue(data.detail);
                    } else {
                        resultRef.current.setValue('HTTP Error. Status: ' + response.status + "\n\n" + json);
                    }
                }).catch(function (err) {
                        resultRef.current.setValue('HTTP Error. Status: ' + response.status);
                    }
                );

            }
        ).catch(function (err) {
            setIsRunningMinizinc(false);
            resultRef.current.setValue('HTTP Error: ' + err);
        });
    }

    function handleEditorDidMount(editor, _monaco) {
        setIsEditorReady(true);
        editorRef.current = editor;
        if (loadedCode && editorRef.current) {
            editorRef.current.setValue(loadedCode);
        }
    }
    function handleResultDidMount(editor, _monaco) {
        resultRef.current = editor;
    }

    function shareCode() {
      fetch('/api/snippet/', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookieValue("csrftoken")
          },
          body: JSON.stringify({
            "code": editorRef.current.getValue()
          }),
        }
      ).then(
        function (response) {
          if (response.status !== 201) {
            return;
          }
          response.json().then(function (data) {
            const url = '/s/' + data['id'];
            window.history.pushState({}, undefined, url);
          });
        }
      ).catch(function (err) {
        console.log(err);
      });
    }

    return (
        <div className="h-full flex flex-col">
            <header className="p-4 bg-gradient-to-br from-indigo-300 to-blue-400">
                <div className="flex justify-between mx-2">
                    <h1 className="text-2xl font-bold font-mono flex">
                        <span className="hidden sm:block">MiniZinc Playground</span>
                        <svg className={"inline-block ml-2 w-6 animate-spin " + (isRunningMinizinc ? "" : "invisible")} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                    </h1>

                    <div className="flex gap-2 items-center">
                        <button title="Run" onClick={runMinizinc} disabled={!isEditorReady || isRunningMinizinc}
                                className="text-gray-800 disabled:text-opacity-70 disabled:cursor-not-allowed border-b-2 border-gray-800 border-opacity-0 hover:border-opacity-100">
                            <svg className="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                        <button title="Share Code" onClick={shareCode} disabled={!isEditorReady} className="border-b-2 border-gray-800 border-opacity-0 hover:border-opacity-100">
                            <svg className="w-8 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                            </svg>
                        </button>
                        <a href="mailto:play@disopt.com" title="Email devs" className="border-b-2 border-gray-800 border-opacity-0 hover:border-opacity-100">
                            <svg className="w-8 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </header>
            <div className="h-full grid grid-rows-2 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2">
                <div className="m-1 border-2 border-indigo-200">
                    <Editor
                        path="editor"
                        height="100%"
                        width="100%"
                        defaultLanguage="minizinc"
                        value={loadedCode}
                        automaticLayout={true}
                        onMount={handleEditorDidMount}
                        options={{
                            glyphMargin: false,
                            folding: false,

                            scrollBeyondLastLine: false,
                            minimap: {
                                enabled: false,
                            },
                        }}
                    />
                </div>
                <div className="m-1 border-2 border-indigo-200">
                    <Editor
                        path="result"
                        height="100%"
                        width="100%"
                        defaultLanguage="json"
                        value={initialResult}
                        automaticLayout={true}
                        onMount={handleResultDidMount}
                        options={{
                            glyphMargin: false,
                            folding: false,

                            formatOnPaste: false,
                            formatOnType: false,
                            scrollBeyondLastLine: false,
                            minimap: {
                                enabled: false,
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default App;
